import AfterSalesService from "../../../services/requests/AfterSalesService";
import { SET_MY_LOT_INFO, SET_LOT_SITUATION, SEND_RESPONSE_COUNTER_OFFER, UPDATE_SALES_STATUS_ID } from "../actions/lot"

const state = {
    data: {},
    situationId: undefined,
    counterOfferText: null,
}

const actions = {
    [SET_MY_LOT_INFO]: ({ commit }, lot) => {
        commit(SET_MY_LOT_INFO, lot);
    },

    [SET_LOT_SITUATION]: ({ commit }, situationId) => {
        commit(SET_LOT_SITUATION, situationId);
    },

    [SEND_RESPONSE_COUNTER_OFFER]: ({commit, rootState}, params) => {
      rootState.notify.duration = 3000;
      return new Promise((resolve, reject)=> {
        let service = new AfterSalesService;
        service.sendResponseCounterOffer(params.lotId, params.accept).then(resp => {
          let text =  "Você aceitou a contra oferta.";
          if (!params.accept)  {
            text = "Você recusou a contra oferta.";
          }
          rootState.notify.color = 'success';
          rootState.notify.message = "Sua resposta foi salva com sucesso!";
          rootState.notify.show = true;
          resolve(resp);
          commit(SEND_RESPONSE_COUNTER_OFFER, text)

        }).catch(err => {
          let data =  err.response.data;
          rootState.notify.color = 'error';
          rootState.notify.message = data.error.message
          if (data.status >= 500) {
            rootState.notify.message = 'Ocorreu um erro ao salvar, tente novamente mais tarde.';
          }
          rootState.notify.show = true;
          reject(err);
        });
      })
    },

    [UPDATE_SALES_STATUS_ID]: (context, params) => {
      return new Promise((resolve, reject) => {
        (new AfterSalesService).updateLotSaleStatusId(params.lotId, params.status)
        .then(resp => {
          if (resp.status >= 200 && resp.status < 300) {
            resolve(true);
          }
          reject(false);
        })
        .catch(() => {
          reject(false);
        });
      });
    }
}

const mutations = {
    [SET_MY_LOT_INFO]: (state, lot) => state.data = lot,

    [SET_LOT_SITUATION]: (state, id) => state.situationId = id,

    [SEND_RESPONSE_COUNTER_OFFER]: (state, value) => state.counterOfferText = value,
}

const getters = {
    getLotData: (state) => state.data,
    getLotSituationId: (state) => state.situationId,
    getResponseCounterOfferText: (state) => state.counterOfferText,
}

export default {
    state,
    actions,
    mutations,
    getters
}
