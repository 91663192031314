import * as Action from "../actions/delivery"
import AfterSalesService from '../../../services/requests/AfterSalesService';
import config from "../../../config";

const state = {
    data: null,
    status: false,
    appLoading: true,
    freight: null,
    textWait: '',
    disabledButtonTohire: true
}

const actions = {
    [Action.GET_DELIVERY_CONTRACTED]: ({ state, commit, rootState }, params) => {
        state.disabledButtonTohire = true;
        state.textWait = 'Aguarde um momento...'
        return new Promise((resolve, reject) => {
            (new AfterSalesService).getDeliveryContracted(params.lotId, params.auctionId)
            .then(response => {
                let result = response.data.data;
                commit(Action.SET_DELIVERY_CONTRACTED, result);

                let statusDelivery = config.statusDelivery.init;
                if (result != null && rootState.lot.situationId >= config.situationLot.delivery) {
                    statusDelivery = config.statusDelivery.contracted; // contratou delivery
                } else if (result == null && rootState.lot.situationId > config.situationLot.delivery) {
                    statusDelivery = config.statusDelivery.uncontracted; // não contratou delivery
                    state.disabledButtonTohire = true;
                }
                commit(Action.SET_STATUS_DELIVERY, statusDelivery);
                resolve(response);
            }).catch(err => {
                reject(err.response);
            }).finally(() => {
                state.textWait = '';
            })
        })
    },

    [Action.CALCULATE_FREIGHT]: ({state, commit}, {lotId, addressId, buyerIdChosen}) => {
        state.disabledButtonTohire = true;
        state.textWait = 'Verificando disponibilidade do serviço de delivery...';
        return new Promise((resolve, reject) => {
            (new AfterSalesService).getValueFreight(lotId, addressId, buyerIdChosen)
              .then(resp => {
                commit(Action.CALCULATE_FREIGHT, resp.data.data)
                resolve(resp.data.data);
                state.disabledButtonTohire = false;
              })
              .catch(err => {
                  state.disabledButtonTohire = true
                  commit(Action.CALCULATE_FREIGHT, null);
                  reject(err.response.data.error.message)
              })
              .finally(() => {
                  state.textWait = '';
              })
        })
    }
}

const mutations = {
    [Action.SET_DELIVERY_CONTRACTED]:  (state, response) => state.data = response,
    [Action.SET_STATUS_DELIVERY]: (state, status) => state.status = status,
    [Action.SET_APP_LOADING]: (state, status) => state.appLoading = status,
    [Action.CALCULATE_FREIGHT]: (state, response) => state.freight = response

}

const getters = {
    [Action.GET_DELIVERY_CONTRACTED]: (state) => state.data,
    [Action.GET_STATUS_DELIVERY]: (state) => state.status,
    [Action.GET_APP_LOADING]: (state) => state.appLoading,
    [Action.GET_FREIGHT]: ({freight}) => freight
}

export default {
    state,
    actions,
    mutations,
    getters
}
