import ImageUserDefault from "./../assets/images/avatar.png";
import ImageLotDefault from "./../assets/images/imagem-nada.png";

function userImage(image) {
  return image ? image : ImageUserDefault;
}

function lotImage(image) {
  return image ? image : ImageLotDefault;
}

export default {
  userImage,
  lotImage,
}
