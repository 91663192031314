// import { config } from 'vue/types/umd';
import Api from './Api'
import ST from './StorageTokens'
import VF from "./VuexFunctions";
import config from './../config'


export default {
  login(email, pass, cb) {
    cb = arguments[arguments.length - 1]

    if (ST.getToken()) {
      if (cb) cb(true)
      this.onChange(true)
      return
    }
    pretendRequest(email, pass, (res) => {
      if (res.authenticated) {
        ST.setToken(res.token)
        // ST.setRefreshToken(res.refresh_token)
        // localStorage.token = res.token
        // localStorage.refresh_token = res.refresh_token
        if (cb) cb(true)
        this.onChange(true)
      } else {
        if (cb) cb(false)
        this.onChange(false)
      }
    })

  },

  getToken() {
    return ST.getToken()
    // return localStorage.token
  },

  logout(cb) {
    ST.clearTokens();
    VF.deleteVuex()
    if (process.env.NODE_ENV == 'production') {
      window.location.href = config.siteSodre + '/apps/logout-site/';
    }
    // delete localStorage.token
    // delete localStorage.refresh_token
    // delete localStorage.user
    if (cb) cb()
    this.onChange(false)
  },

  loggedIn() {
    return !!ST.getToken()
    // return !!localStorage.token
  },

  onChange() {
    // console.log(status)
  },

  async sendResetPassword(email) {
    let endpoint = "/auth/recover-password";

    return await Api.post(endpoint, {
      email: email
    });
  }
}

function pretendRequest(login, pass, cb) {
  Api.post('/auth/login', {
    "login": login,
    "password": pass,
    "authSite": config.authSite
  })
  .then(
    res => {
      if (res.data.data.access_token) {
        cb({
          authenticated: true,
          token: res.data.data.access_token,
        })
      }

      return true
    },
    err => {
      cb({ authenticated: false })
      throw new Error(err)
    }
  )
}

// function pretendRequest(email, pass, cb) {
//   Api.post('/auth/token', {
//     "grant_type": "password",
//     "username": email,
//     "password": pass,
//     "authSite": "06c028697564f66f0791ef403900605a",
//   })
//   .then(
//     res => {
//       console.log(res.data.data)

//       if (res.data.data.access_token) {
//         cb({
//           authenticated: true,
//           token: res.data.data.access_token,
//           refresh_token: res.data.data.refresh_token
//         })
//       }
//       return true
//     },
//     err => {
//       cb({ authenticated: false })
//       throw new Error(err)
//     }
//   )
// }
