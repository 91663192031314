const check = {
  getQuerystring() {
    let listParams = {};
    let params = window.location.search;
    if (params) {
      params = params.replace("?", "").split("&");
      params.forEach((item) => {
        let v = item.split("=");
        listParams[v[0]] = v[1];
      });
    }

    return listParams;
  },

  getVersion() {
    let ref = 'v1';
    const listParams = this.getQuerystring();
    if (listParams.ref && listParams.ref == "v2") {
      ref = 'v2';
    }

    return ref
  }
}

export default check
