export default {
  SET_DELEGATION_OBJ(state, delegation) {
    state.delegation = delegation;
  },
  SET_ALERT_OBJ(state, alert) {
    state.alert = alert;
  },
  SET_USER_LOGGED_OBJ(state, user) {
    state.user = user;
  },
  SET_SCHEDULE(state, schedule) {
    state.schedule = schedule;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_SEDEX(state, sedex) {
    state.sedex = sedex;
  }
}
