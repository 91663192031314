import AfterSalesService from "../../../services/requests/AfterSalesService"
import {
  GET_PAYMENT_STARTED,
  SET_PAYMENT_STARTED,
  SET_DISABLED_METHODS_PAYMENT,
  GET_DISABLED_METHODS_PAYMENT,
  SET_VALUES,
  GET_VALUES,
  FIND_PAYMENT_STARTED
} from "../actions/payment"

const state = {
  payment: null,
  values: null,
  disabledMethodsPayment: false,
}

const actions = {

  [SET_VALUES]: ({commit}, lotIdMd5) => {
    return new Promise((resolve, reject) => {
      let service = new AfterSalesService;
      service.getValuesLot(lotIdMd5)
      .then(resp => {
        const { data } = resp.data;
        commit(SET_VALUES, data);
        resolve(data);
      })
      .catch (e => {
        reject(e);
        throw new Error (e.message)
      })
    })
  },

  [FIND_PAYMENT_STARTED]: ({commit}, lotId) => {
    return new Promise((resolve, reject) => {
      let service = new AfterSalesService;
      service.getPaymentProcessed(lotId)
        .then(resp => {
          const { data } = resp.data;
          commit(SET_PAYMENT_STARTED, data);
          resolve(data);
        })
        .catch(err => {
          reject(err.response);
          throw new Error (err.message);
        })
    })
  },

  [SET_PAYMENT_STARTED]: ({ commit }, value) => {
    commit(SET_PAYMENT_STARTED, value);
    return Promise.resolve(true)
  },

  [SET_DISABLED_METHODS_PAYMENT]: ({ commit }, value) => {
    commit(SET_DISABLED_METHODS_PAYMENT, value);
  },
}

const mutations = {
  [SET_PAYMENT_STARTED]: (state, value) => state.payment = value,

  [SET_DISABLED_METHODS_PAYMENT]: (state, value) => state.disabledMethodsPayment = value,

  [SET_VALUES]: (state, data) => state.values = data,
}

const getters = {
  [GET_VALUES]: (state) => state.values,

  [GET_PAYMENT_STARTED]: (state) => state.payment,

  [GET_DISABLED_METHODS_PAYMENT]: (state) => state.disabledMethodsPayment
}

export default {
  state,
  actions,
  mutations,
  getters
}
