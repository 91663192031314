<template>
  <v-dialog
    v-model="show"
    width="500"
  >
    <v-card>
      <v-card-title
        class="title-term headline bk-blue2"
        primary-title
      >
        {{ title }}
      </v-card-title>

      <v-card-text>
        <slot />
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <v-btn
          v-if="this.$route.name != 'afterSalesUpload'"
          class="btn btn-primary"
          @click="closeDialog()"
        >
          OK
        </v-btn>
        <v-btn
          v-else
          class="btn btn-primary mt-4"
          @click.prevent="reloadPage()"
        >
          <v-icon left>
            mdi-refresh
          </v-icon>
          Tentar novamente
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Aviso"
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    closeDialog() {
      this.$emit("update:show", false);
    },
    reloadPage() {
      window.location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./../assets/sass/colors.scss";

.v-card__title {
  display: block;
  text-align: center;
  color: #fff;
}
.v-card__text {
  font-size: 1.5rem;
  font-weight: 500;
  color: $blue2;
  text-align: center;
  margin: 30px 0;
}

.btn {
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}
</style>
