import Vue from 'vue';

Vue.filter('toCPF', function (value) {
  return Vue.$helpers.toCPF(value)
});

Vue.filter('strLen', function (value, size) {
  return Vue.$helpers.strLen(value, size)
});

Vue.filter('toDateView', function (value) {
  return Vue.$helpers.toDateView(value)
});

Vue.filter('toPhoneView', function (value) {
  return Vue.$helpers.toPhoneView(value)
});

export default Vue;
