import AfterSalesService from '../../../services/requests/AfterSalesService';
import { CREATE_PROOF_PAY, GET_DOCUMENTS, PROCESS_ID, LOADER, SEND_FILES, GET_PROCESS, HAS_FILE_PENDING } from '../actions/uploadDocuments'

const state = {
  documents: null,
  loader: true,
  processId: null,
};

const actions = {
  [GET_DOCUMENTS]: ({ commit }, args) => {
    commit(LOADER, true);
    return new Promise((resolve, reject) => {
      (new AfterSalesService).getDocumentsFromStorage(
        args.buyerIdChosen,
        args.lotId,
        args.auctionId,
        args.isScrap,
        args.address.number,
        args.address.zipcode
      )
        .then(resp => {
          const data = resp.data.data;
          let docs = data.sort((a, b) => (a.type.name > b.type.name) ? 1 : (a.type.name < b.type.name) ? -1 : 0)
          commit(PROCESS_ID, docs[0].processId);
          commit(GET_DOCUMENTS, docs);
          resolve(docs)
        })
        .catch(err => {
          commit(GET_DOCUMENTS, null);
          commit(PROCESS_ID, null);
          reject(err.response);
        })
        .finally(() => {
          commit(LOADER, false);
        })
    })
  },

  [SEND_FILES]: ({ commit }, payload) => {
    commit(LOADER, true);
    return new Promise((resolve, reject) => {
      let service = new AfterSalesService;
      service.sendFileFromStorage(payload.process_id, payload.data)
        .then(resp => {
           resolve(resp.status == 200);
        }).catch(err => {
          reject('ocorreu um erro ao enviar os arquivos tente novamente ('+ err +')');
        })
        .finally(() => {
          commit(LOADER, false);
        })
    })
  },

  [CREATE_PROOF_PAY]: (context, params) => {
    let service  = new AfterSalesService;
    service.createProfPayFromStorage(params)
      .then(resp => resp.data.data)
      .catch(err => {
        throw new Error("Ocorreu um erro: " + err);
      }).finally(()=> context.commit(LOADER, false));
  },

  [GET_PROCESS]: ({commit}, params) => {
    return new Promise((resolve, reject) => {
      let service  = new AfterSalesService;
      service.getProcess(params.lotId, params.auctionId)
      .then(resp => {
        let process = resp.data.data;
        if (Object.values(process).length > 0) {
          commit(PROCESS_ID, process[0].id);
          resolve(resp.data.data)
        } else {
          commit(PROCESS_ID, 0);
          resolve('Precesso não localizado.');
        }
      })
      .catch(err => {
        reject({error: true})
        throw new Error("Ocorreu um erro ao buscar o processo: " + err);
      });
    })
  },

  
  [HAS_FILE_PENDING]: ({commit}, params) => { // eslint-disable-line no-unused-vars
    return new Promise((resolve) => {
      let service = new AfterSalesService;
      service.endpointHasFilesPending(params.lotId, params.auctionId)
        .then(resp => {
          let result = resp.data.data;
          resolve({containsFilePending: result});
        })
        .catch(err => {
          throw new Error("Ocorreu um erro ao buscar o processo: " + err);
        })
    })
  }

};

const mutations = {
  [GET_DOCUMENTS]: (state, value) => state.documents = value,
  [LOADER]: (state, value) => state.loader = value,
  [PROCESS_ID]: (state, value) => state.processId = value,
};

const getters = {
  [GET_DOCUMENTS]: state => state.documents,
  [LOADER]: state => state.loader,
  [PROCESS_ID]: state => state.processId
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
