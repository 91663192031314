import Vue from 'vue'
import router from './router/index';
import store from './store/index';
// import Api from './services/Api.js';
import './assets/sass/style.scss';
import App from './App.vue';
import './filters/filters';
import infiniteScroll from 'vue-infinite-scroll'
import Helpers from './helpers/index';
import vuetify from './plugins/vuetify';
import './registerServiceWorker'
import vueAwesomeCountdown from 'vue-awesome-countdown';
import VueCompositionAPI from '@vue/composition-api'

require('animate.css');

// Vue.prototype.$http = Api;
Vue.config.productionTip = false;
Vue.use(infiniteScroll);
Vue.use(Helpers);
Vue.use(vueAwesomeCountdown, 'vue-countdown')
Vue.use(VueCompositionAPI)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
