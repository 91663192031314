const helpers = {
  /**
   * Recebe objeto e cria query string (ex. auctionId=2515&buyerId=52664&page=1)
   * @param {Array} Objeto chave e valor para criar query string
   */
  queryString(params) {
    var queryString = Object.keys(params).map(function (key) {
      return key + '=' + params[key]
    }).join('&');
    return queryString;
  },

  /**
   * Formata CPF ou CNPJ  para XXX.XXX.XXX-XX ou XX.XXX.XXX/XXXX-XX
   * @param {String} value
   */
  toCPF(value) {
    if (value) {
      value = value.replace(/[^0-9A-z]|_/g, '');
      if (value.length <= 11) {
        value = value.replace(/^(\d{3})(\d{3})(\d{3})([A-z0-9]{2}).*/, '$1.$2.$3-$4');
        return `${value}`;
      } else {
        value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5');
        return `${value}`;
      }
    }
  },

  /**
   * Define um tamanho máximo para a string
   * @param {String} value
   * @param {Integer} size
   */
  strLen(value, size) {
    size = (size) ? size : 60;
    value = value.substring(0, size);
    return value + ' ...';
  },

  /**
   * Formata a de XXXX-XX-XX para XX/XX/XXXX ou
   * Formata a de XXXX-XX-XX YY:YY:YY para XX/XX/XXXX YY:YY
   * @param {String} value
   * @param {boolean} timeView
   */
  toDateView(value, timeView = false) {
    const [date, time] = value.split(" ");
    const [year, month, day] = date.split("-");
    if (timeView) {
      const [hour, minute] = time.split(":");
      return `${day}/${month}/${year} ${hour}:${minute}`;
    }

    return `${day}/${month}/${year}`;
  },

  dateFromDB(dateInput) {
    return  dateInput.split('/').reverse().join('-');
  },

  /**
   * Formata numero de celular com DDD
   * @param {String} value
   */
  toPhoneView(value) {
    value = value.replace(/[^0-9]|_/g, '');
    if (value.length == 10) {
      value = value.replace(/^(\d{2})(\d{4})(\d{4}).*/, '($1) $2-$3');
    }
    if (value.length == 11) {
      value = value.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');
    }

    return value
  },

  /**
   * abrevia partes de uma string
   * @param {String} strValue
   */
  abbreviateString(strValue) {
    // EX. São José do Vale do Rio Preto 25780-000
    // EX. Vila Bela da Santíssima Trindade 78245-000
    const nome = strValue.replace(/\s+/gi, ' ').trim();

    if (nome.length < 25) {
      return nome;
    }

    var array_nome = nome.split(' ');
    if (array_nome.length > 2) {
      return array_nome.map(function (parte, index, nomes) {
        if (parte.length < 3 && index != 0 && ++index != nomes.length) {
          return '';
        }

        if (parte.length > 3 && index != 0 && ++index != nomes.length) {
          return parte[0] + '.';
        }
        return parte;
      }).join(' ').replace('  ', ' ');
    } else {
      return strValue.replace('  ', ' ');
    }
  },

  /**
   * Remove caracteres de uma numeração deixando apenas numeros XX.XX-X para XXXXX
   * @param {Number} value
   */
  clearNumber (value) {
    return  value.replace(/[^0-9]|_/g, '');
  },

  /**
   * Formata valor para real
   * @param {Number} value
   */
  toCoinView (value) {
    return parseFloat(value).toLocaleString("pt-br", {
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    });
  },
}

export default {
  install(Vue) {
    Vue.$helpers = helpers
    Object.defineProperties(Vue.prototype, {
      $helpers: {
        get() {
          return helpers
        }
      }
    })
  }
}
