import api from '../Api'

class AfterSalesService {

    async getLotsAcquired(params) {
        let url = 'aftersale/lot/acquired?' + params;
        return await api
          .get(url)
          .then(res => res.data)
          .catch(err => {
            throw new Error(err);
          })
    }

    async getInfoSaleNote(lotId, auctionId, buyerId, bradesco=0, status=1) {
        return await api.get('/aftersale/sale-note', {
             params: {lotId, auctionId, buyerId, bradesco, "statusLot":status}
        });
    }

    async checkUserDocument(document, lotId, buyerId, buyerName) {
        return await api.get('/aftersale/verify-situation/' + document, {
          params : {
            lotId: lotId,
            buyerIdChosen: buyerId,
            buyerName: buyerName,
          }
        });
    }

    async getDelegation(auctionId, lotId) {
        return await api.get('/aftersale/buyer/available', {
            params : {
                auctionId: auctionId,
                lotId: lotId,
                typeId: 1

            }
        })
    }

    async savePreSaleNoteAddress(params) {
        return await api.post("/aftersale/pre-sale-note", params);
    }

    async getDocuments(lotId, auctionId, buyerId) {
        return await api.get("/aftersale/documents", {
            params: {
                "lotId":lotId,
                "auctionId": auctionId,
                "buyerIdChosen":buyerId
            }
        });
    }

    async getDocumentsFromStorage(buyerIdChosen, lotId, auctionId, isScrap, addressNumber, addressZipcode) {
      return await api.get('/aftersale/documents/storage/files', {
        params: {
          buyerIdChosen,
          lotId,
          auctionId,
          isScrap,
          addressNumber,
          addressZipcode
        }
      })
    }

    async sendFileFromStorage(processId, formData) {
      return await api.post(`/aftersale/documents/storage/files/${processId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    }

    async endpointHasFilesPending(lotId, auctionId) {
        return api.get(`/aftersale/documents/storage/check-file-pending/?lotId=${lotId}&auctionId=${auctionId}`);
    }

    async updateLotSaleStatusId(lotId, status) {
      return await api.put('/aftersale/documents/storage/lot/update-status-sales-id', {
        lotId: lotId,
        sale_status_id: status
      });
    }

    async createProfPayFromStorage(params) {
      return await api.post(`/aftersale/documents/storage/attach-files/${params.processId}`, {
        lotId: params.lotId,
        auctionId: params.auctionId,
        doctype_id: params.doctypeId
      });
    }

    async getProcess(lotId, auctionId) {
      return await api.get(`/aftersale/documents/storage/process`, {
        params: {
          lotId: lotId,
          auctionId: auctionId
        }
      })
    }

    async submitDocsForSaleNote(formdata) {
        return await api.post("/aftersale/documents", formdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    async getTermResposability(params) {
        let linkTerm = `aftersale/term-responsability?lotId=${params.lotId}&auctionId=${params.auctionId}&clientId=${params.clientId}`
        return await api.get(linkTerm, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        })
    }

    async submitProcessPayment(type, data) {
      let path = 'aftersale/process-payment/' + type;
      return await api.post(path, data);
    }

    async getPaymentProcessed(lotId) {
      return await api.get(`/aftersale/payment/initialized/${lotId}`);
    }

    async getValuesLot(lotIdMd5) {
      return await api.get(`/aftersale/payment/values?lotIdMd5=${lotIdMd5}`);
    }

    async getInfoBillet(lotId, isBradesco) {
        let bradesco = isBradesco ? 1 : 0;
        let path = "/aftersale/billet/";
        if (bradesco > 0) {
            path = "/aftersale/billet/bradesco/";
        }
        return await api.get(path + lotId);
    }

    async getBilletPdf(linkBillet) {
        return await api.get(linkBillet, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        });
    }

    async getValueFreight(lotId, addressId, buyerId) {
        return await api.get("/aftersale/delivery/freight", {
            params: {
                addressId: addressId,
                buyerIdChosen: buyerId,
                lotId: lotId
            }
        });
    }

    async getDeliveryContracted(lotId, auctionId) {
        return await api.get('/aftersale/delivery/contracted', {
            params: { lotId, auctionId }
        })
    }

    async saveDelivery(params) {
        return await api.post('/aftersale/delivery/contracted', params);
    }

    async insertProofPayment(params) {
        return await api.post('aftersale/documents/proof-payment', params);
    }

    async downloadSaleNote(salenoteId) {
        return await api.get('/scheduling/lot/sale-note/download/' + salenoteId, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        });
    }

    async downloadEdital(auctionId, dateAuction) {
        return await api.get('/aftersale/documents/edital', {
            params: {
                auctionId: auctionId,
                dateAuction: dateAuction
            },
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        })
    }

    async downloadBpv(lotId, typeDocument) {
        return await api.get('/aftersale/documents/bpv', {
            params: {
                lotId: lotId,
                typeDocument: typeDocument
            },
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        })
    }

    async getAllFileBpv(lotId) {
      return await api.get(`/aftersale/documents/${lotId}/bpv/list`);
    }

    async sendResponseCounterOffer(lotId, accept) {
      return await api.patch('/aftersale/lot/counter-offer', {
        'lotId': lotId,
        'accept': accept
      })
    }

    async getAuctions(buyerId) {
      return await api.get('/aftersale/auctions', {
        params: {
          buyer: buyerId
        }
      })
    }

    async getLinkMercadoPago(lotId) {
      return await api.get(`/aftersale/link-payment?lotId=${lotId}`)
    }

    async getInstallments(lotIdMd5)
    {
      return await api.get(`/aftersale/payment/installments?lotIdMd5=${lotIdMd5}`);
    }

    async getLotBy(lotId) {
        return await api.get(`/aftersale/lot/acquired/${lotId}/show`);
    }
}

export default AfterSalesService
