
import { auctioneerPublicKEY } from './pubkeys';

const config = {
  baseUrl: process.env.VUE_APP_API,
  // baseUrl: 'http://192.168.40.229:8080',
  apiPass: 'ssapp:devel',

  authSite: '06c028697564f66f0791ef403900605a',

  recaptcha: '6LfghnQfAAAAACY3YxxjuCRsJ1QwwSB7PmnuZ47R',

  typeDocumentsBPV: {
    "chassi": 1,
    "fiscalNote": 2,
    "fiscalNoteAndChassi": 3
  },

  situationLot: {
    confirmation_data: 1,
    upload_document: 2,
    delivery: 3,
    payment: 4,
    sales_note_issued: 5,
    scheduling_and_withdrawal: 6,
    finished: 7,
    lotPaid: 8
  },

  statusDelivery: {
    init: 1,
    contracted: 2,
    uncontracted: 3
  },

  statusDocuments: {
    okay: 1,
    waiting_for_approval: 2,
    disapprove: 8,
    approved_with_reservation: 9,
    waiting_for_reanalysis: 10,
    blocked: 11,
    approved_by_sac:12,
  },

  idDocumentOptional: {
    termResponsibility_second_page: 21
  },

  keyAfterSaleLocalStorage: 'aftersales',

  dateImplamentation: '2022-12-19',
  dateImplementationStorage: '2024-04-09',
  auctionsReleasedForStorage: [7530, 24774, 24817, 24965, 24944, 24967, 25038, 25067, 25080],
  testUsers: {
    // "mainLots": [323335, 292000, 577472, 339058, 85813, 101434, 522356, 7839, 178362, 60624],
    // "favorite": [323335, 292000, 577472, 339058, 101434, 522356, 7839],
    // "disputedLot": [323335, 292000, 577472, 339058, 101434, 522356, 7839],
    // "documents": [323335, 292000, 577472, 339058],
    // "site": [323335, 292000, 577472, 339058, 101434, 522356, 7839]
  },
  siteSodre: 'https://www.sodresantoro.com.br',

  mp_public_key: {
    [auctioneerPublicKEY.LF.id] : auctioneerPublicKEY.LF.value,
    [auctioneerPublicKEY.JE.id] : auctioneerPublicKEY.JE.value,
    [auctioneerPublicKEY.MOACIR.id] : auctioneerPublicKEY.MOACIR.value,
    [auctioneerPublicKEY.FLAVIO.id] : auctioneerPublicKEY.FLAVIO.value,
    [auctioneerPublicKEY.JULIOC.id] : auctioneerPublicKEY.LF.value,
    [auctioneerPublicKEY.OTAVIO.id] : auctioneerPublicKEY.OTAVIO.value,
    [auctioneerPublicKEY.MARIANA.id] : auctioneerPublicKEY.MARIANA.value,
    [auctioneerPublicKEY.CAROLINA.id]: auctioneerPublicKEY.CAROLINA.value,
    [auctioneerPublicKEY.SS.id]: auctioneerPublicKEY.SS.value,
  },

  minimumEnablePayCardCredit: 40,

  // mp_public_key_dev: "APP_USR-bead5a69-54e9-4491-bfe5-0268e4751c1a",
  mp_public_key_dev: "TEST-454f6c07-4723-4b93-8e71-7fb17299140f",

  testUserMP: {
    cpf: 39149551558,
    email: 'test_user_419768067@testuser.com'
  },

  disabledPayment: {
    credit_card: [25811],
    pix: [],
    billet: []
  }


}

export default config;
