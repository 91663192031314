export default {
  setDelegation(context, data) {
    context.commit('SET_DELEGATION_OBJ', data)
  },
  setAlert(context, data) {
    context.commit('SET_ALERT_OBJ', data)
  },
  setUserLogged(context, data) {
    context.commit('SET_USER_LOGGED_OBJ', data)
  },
  setSchedule(context, data) {
    context.commit('SET_SCHEDULE', data)
  },
  setToken(context, data) {
    context.commit('SET_TOKEN', data)
  },
  setSedex(context, data) {
    context.commit('SET_SEDEX', data)
  },
}
