import axios from "axios";
import config from "./../config";
import ST from "./StorageTokens";
import store from "./../store/index";
import VF from "./VuexFunctions";

// Criando obj do axios
const api = axios.create({
  baseURL: config.baseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

/**
 * Configurando interceptador de requisicao
 * se ja tem token usa header com Bearer se não usa Basic
 */
api.interceptors.request.use(async (conf) => {
  try {
    const token = ST.getToken();
    if (token) {
      conf.headers.Authorization = `Bearer ${token}`;
    } else {
      conf.headers.Authorization = "Basic " + window.btoa(config.apiPass);
    }
    return conf;
  } catch (err) {
    throw new Error("Ocorreu um erro: " + err);
  }
});

/**
 * Configurando interceptador para refresh token
 * Se token expirar faz requisicao para renovar o token e refaz ultima requisicao com o token atualizado.
 */
api.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) => {
    if (error.message && error.message === "Network Error") {
      store.dispatch("setAlert", {
        show: true,
        title: "Aviso",
        message:
          "Falha da comunicação com o servidor, tente novamente mais tarde.",
        type: "default",
      });
      return;
    }

    if (error.response.status === 404 || error.response.status === 500) {
      store.dispatch("setAlert", {
        show: true,
        title: "Aviso",
        message: "Ocorreu um erro, tente novamente mais tarde.",
        type: "default",
      });
      return;
    }

    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (
        localStorage.getItem("_retry") &&
        window.location.pathname == "/login"
      ) {
        store.state.error.login.message = "Login ou senha inválidos";
        store.state.error.login.show = true;
        return Promise.reject(error);
      }

      const token = ST.getToken();
      return axios
        .post(
          config.baseUrl + "/auth/refresh",
          {},
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(
          ({ data }) => {
            if (localStorage.getItem("_retry")) {
              localStorage.removeItem("_retry");
            }
            ST.setToken(data.data.access_token);
            originalRequest.headers["Authorization"] =
              "Bearer " + data.data.access_token;
            return axios(originalRequest);
          },
          () => {
            originalRequest._retry = true;
            ST.clearTokens();
            VF.deleteVuex();
            localStorage.setItem("_retry", true);
            window.location = "/login?redirect=" + window.location.pathname;
          }
        );
    }
    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;

    //   // const refreshToken = window.localStorage.getItem('refresh_token');
    //   const refreshToken = ST.getRefreshToken();
    //   return axios.post(config.baseUrl + '/auth/token', {
    //     grant_type: "refresh_token",
    //     refresh_token: refreshToken
    //   }, {
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json',
    //       'Authorization': 'Basic ' + window.btoa(config.apiPass)
    //     }
    //   })
    //     .then(({ data }) => {
    //       ST.setToken(data.access_token);
    //       ST.setRefreshToken(data.refresh_token);
    //       // window.localStorage.setItem('token', data.access_token);
    //       // window.localStorage.setItem('refresh_token', data.refresh_token);
    //       axios.defaults.headers.common['Authorization'] = 'Basic ' + window.btoa(config.apiPass);
    //       originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
    //       return axios(originalRequest);
    //     },
    //       () => {
    //         ST.clearTokens();
    //         VF.deleteVuex()
    //         // delete localStorage.token
    //         // delete localStorage.refresh_token
    //         // delete localStorage.user
    //         window.location = "/login?redirect=" + window.location.pathname;
    //       }
    //     );
    // }

    return Promise.reject(error);
  }
);

export default api;
// https://alligator.io/vuejs/rest-api-axios/
