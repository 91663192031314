export const auctioneerPublicKEY = {
  "LF" : {
    id: 1,
    value: 'APP_USR-958bc0a9-a5a9-4aa0-b526-8acfac392c52'
  },
  "JE" : {
    id: 2,
    value: 'APP_USR-b9985826-b2dd-4bf9-bbb0-2bdd74e78492'
  },
  "MOACIR" : {
    id: 5,
    value: 'APP_USR-7e7424ab-608b-42c8-80d6-338d04877109'
  },
  "FLAVIO" : {
    id: 10,
    value: 'APP_USR-94d4d13e-fc12-45d2-b280-f814bba78abf'
  },
  "JULIOC" : {
    id: 19,
    value: null
  },
  "OTAVIO" : {
    id: 30,
    value: 'APP_USR-be108e9f-c7bd-480e-a553-54a794428f16'
  },
  "MARIANA": {
    id: 31,
    value: 'APP_USR-fc8e80c5-20e4-47d1-84b8-ebc575373bbe'
  },
  "CAROLINA": {
    id: 45,
    value: 'APP_USR-e4d6b2e6-bd7d-4ad4-a655-3a851b746322'
  },
  "SS": {
    id: 41,
    value: 'APP_USR-e4d6b2e6-bd7d-4ad4-a655-3a851b746322'
  },
}
