export default {
  getToken() {
    var name = "token=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  },
  setToken(token, exdays) {
    exdays = 1;
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    let prod = window.location.hostname.split('.').splice(-3).join('.')

    if (process.env.NODE_ENV == 'production' && prod == 'sodresantoro.com.br') {
      document.cookie = "token=" + token + ";" + expires + "; domain=.sodresantoro.com.br; path=/";
    } else {
      document.cookie = "token=" + token + ";" + expires + "; path=/";
    }
  },
  deleteToken() {
    let prod = window.location.hostname.split('.').splice(-3).join('.')

    if (process.env.NODE_ENV == 'production' && prod == 'sodresantoro.com.br') {
      document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.sodresantoro.com.br; path=/";
    } else {
      document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  },
   clearTokens() {
    this.deleteToken()
    // this.deleteRefreshToken()
  },
  // getRefreshToken() {
  //   var name = "refresh_token=";
  //   var decodedCookie = decodeURIComponent(document.cookie);
  //   var ca = decodedCookie.split(';');
  //   for (var i = 0; i < ca.length; i++) {
  //     var c = ca[i];
  //     while (c.charAt(0) == ' ') {
  //       c = c.substring(1);
  //     }
  //     if (c.indexOf(name) == 0) {
  //       return c.substring(name.length, c.length);
  //     }
  //   }
  //   return false;
  // },
  // setRefreshToken(refreshToken, exdays) {
  //   exdays = 1;
  //   var d = new Date();
  //   d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  //   var expires = "expires=" + d.toUTCString();

  //   if (process.env.NODE_ENV == 'production') {
  //     document.cookie = "refresh_token=" + refreshToken + ";" + expires + "; domain=.sodresantoro.com.br; path=/";
  //   } else {
  //     document.cookie = "refresh_token=" + refreshToken + ";" + expires + "; path=/";
  //   }
  // },
  // deleteRefreshToken() {
  //   if (process.env.NODE_ENV == 'production') {
  //     document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.sodresantoro.com.br; path=/";
  //   } else {
  //     document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //   }
  // },



  // Implementacao Feita em localStorage
  // getToken() {
  //   console.log(localStorage.getItem('token'))
  //   if (localStorage.getItem('token')) {
  //     return localStorage.getItem('token');
  //   }
  //   return false;
  // },
  // getRefreshToken() {
  //   console.log(localStorage.getItem('refresh_token'))
  //   if (localStorage.getItem('refresh_token')) {
  //     return localStorage.getItem('refresh_token');
  //   }
  //   return false;
  // },
  // setToken(token) {
  //   localStorage.setItem('token', token);
  // },
  // setRefreshToken(refreshToken) {
  //   localStorage.setItem('refresh_token', refreshToken);
  // },
  // deleteToken() {
  //   delete localStorage.token
  // },
  // deleteRefreshToken() {
  //   delete localStorage.refresh_token
  // },
  // clearTokens() {
  //   delete localStorage.token
  //   delete localStorage.refresh_token
  //   delete localStorage.user
  // },
}
