import Vue from 'vue';
import Vuetify, {VApp} from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';

Vue.use(Vuetify);

const opts = {
    components: {
        VApp
    },
    directives: {
        Ripple
    }
}

export default new Vuetify(opts);
