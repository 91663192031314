import Auth from './../services/Auth'

const RF = {
  requireAuth(to, from, next) {
    if (!Auth.loggedIn()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  },
  logout(to, from, next) {
    Auth.logout()
    next('/login')
  }
}

export default RF;
